<template>
  <div class="ps-page--my-account">
    <div class="ps-panel__header" style="background-color:#000">
      <div class="row">
        <div class="col-4 left-align">
          <router-link :to="{ name: 'dial_from_263' }">
            Dialfrom+263PH
          </router-link>
        </div>
        <div class="col-4">
          <a class="ps-logo" href="/">
            <img
              src="/img/logo.png"
              alt="Pahukama"
              max-width="100%"
              height="auto"
            />
          </a>
        </div>
        <div class="col-4 right-align">
          <router-link :to="{ name: 'topup_now ' }">SmartTopUpPH</router-link>
        </div>
      </div>
    </div>
    <div class="ps-my-account">
      <div class="ps-container">
        <form class="ps-form--account ps-tab-root">
          <ul class="ps-tab-list">
            <li :class="isActive('login')">
              <router-link :to="{ name: 'login' }">Login</router-link>
            </li>
            <li :class="isActive('register')">
              <router-link :to="{ name: 'register' }">Register</router-link>
            </li>
          </ul>
          <div class="ps-tabs">
            <router-view></router-view>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BaseAuth",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  methods: {
    isActive(routeName) {
      return routeName === this.$route.name;
    },
  },
};
</script>

<style scoped></style>
